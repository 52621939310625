import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    private http: HttpClientService
  ) { }

  getSurveys(state?: any) {
    return this.http.get(`${API_CONSTANTS.SURVEY_FORM}`, state).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
