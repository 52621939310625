import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '../services';
import { MESSAGE_CONSTANTS } from '../constants';
import { Router } from '@angular/router';
import { ViventUtil } from '../utils';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private viventUtil: ViventUtil,
        private toasterService: ToasterService
    ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request.clone()).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.toasterService.show("Error", MESSAGE_CONSTANTS.UNAUTHORIZED);
            this.viventUtil.clearStorage();
            this.router.navigate(['/not-authorized']);
          } else if (error.status == 403) {
            this.toasterService.show("Error", MESSAGE_CONSTANTS.FORBIDDEN);
          } else if(error.status == 404) {
            this.viventUtil.clearStorage();
            this.router.navigate(['/not-found']);
          } else if (error.status === 0) {
            this.toasterService.show("Error", MESSAGE_CONSTANTS.SERVER_ERROR);
            return throwError(error);
          }
          this.toasterService.show("Error", error.error.message);
          return throwError(error);
        })
      );  
  }
}