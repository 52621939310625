import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ViventUtil } from '../utils/vivent.util';

const API_BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  constructor(
    private http: HttpClient,
    private viventUtil: ViventUtil
    ) { }

  createHeader() {
    let headers = new HttpHeaders();
    const loggedInUser = this.viventUtil.getLoggedInUser();
    if (loggedInUser) {
      const token = loggedInUser?.token;
      headers = headers.set('Bearer', token);
    }

    return headers;
  }

  get<T>(url: string, params?: any) {
    return this.http.get(API_BASE_URL + url, { 
      params 
    });
  }

  post(url: string, data: any) {
    return this.http.post(API_BASE_URL + url, data, {
    });
  }

  put(url: string, data: any) {
    return this.http.put(API_BASE_URL + url, data, {
    });
  }

  remove(url: string, params?: any) {
    return this.http.delete(API_BASE_URL + url, { 
      params,
    });
  }

  patch(url: string, data: any) {
    return this.http.patch(API_BASE_URL + url, data, {
    });
  }
}
