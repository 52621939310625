import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_CONSTANTS, STORAGE_CONSTANT } from '../constants';
import { HttpClientService } from './http-client.service';
import { TransferStateService } from './transfer-state.service';
import surveys from '../json/surveys.json';
@Injectable({
  providedIn: 'root'
})
export class JigsawService {

  surveys = surveys;

  constructor(
    private http: HttpClient,
    private httpClient: HttpClientService,
    private transferStateService: TransferStateService
  ) { }

  getZipCodes() {
    const zipCodes = STORAGE_CONSTANT.ZIP_CODES;
    const cachedResponse: any = this.transferStateService.getNgState<any>(zipCodes);
    if (!cachedResponse) {
      return this.http.get(`${environment.jigsawServiceUrl}/acf/v3/options/options`).pipe(
        tap((res: any) => {
          this.transferStateService.setNgState<any>(zipCodes, res)
        })
      );
    }
    return of(cachedResponse);
  }

  getSurveys() {
    // const surveys = STORAGE_CONSTANT.SURVEYS;
    // const cachedResponse: any = this.transferStateService.getNgState<any>(surveys);
    // if (!cachedResponse) {
    //   return this.http.get(`${environment.jigsawServiceUrl}/gf/v2/forms/2`).pipe(
    //     tap((res: any) => {
    //       this.transferStateService.setNgState<any>(surveys, res)
    //     })
    //   );
    // }
    // return of(cachedResponse);
    return of(surveys);
  }

  getOrderDetail(orderId: string) {
    const orderDetail = STORAGE_CONSTANT.ORDER_DETAIL;
    const cachedResponse: any = this.transferStateService.getNgState<any>(orderDetail);
    if (!cachedResponse) {
      return this.httpClient.get(API_CONSTANTS.ORDER + `/${orderId}`).pipe(
        tap((res: any) => {
          this.transferStateService.setNgState<any>(orderDetail, res)
        })
      );
    }
    return of(cachedResponse);
  }
}
