import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { CountdownComponent } from 'ngx-countdown/countdown.component';
import { CountdownEvent } from 'ngx-countdown/interfaces';
import { finalize } from 'rxjs/operators';
import { MESSAGE_CONSTANTS } from 'src/app/constants';
import { ViventUtil } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { DataService, SsoService, ToasterService } from '../../../services';

@Component({
  selector: 'app-verification-pin-modal',
  templateUrl: './verification-pin-modal.component.html',
  styleUrls: ['./verification-pin-modal.component.scss']
})
export class VerificationPinModalComponent implements OnInit {
  @ViewChild('codeInput', { static: true }) codeInput!: CodeInputComponent;
  @ViewChild('cd', { static: false }) private countdown!: CountdownComponent;
  username!: string;
  code!: string;
  loading: boolean = false;
  submitted: boolean = false;
  formData: any = {};
  verificationCodeSent: boolean = false;
  timeValidity: number = 600; // 10 min
  codeExpired: boolean = false;
  id: string = ''
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ssoService: SsoService,
    private viventUtil: ViventUtil,
    private dataService: DataService,
    private toasterService: ToasterService,
    public modalRef: MdbModalRef<VerificationPinModalComponent>,
  ) { }

  ngOnInit(): void {
    this.id = this.formData?.id;
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    this.code = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: any) {
    this.verify();
  }

  verify() {
    if (this.code) {
      this.loading = true;
      this.ssoService.verifySsoOtp({ id: this.id, otp: this.code })
        .pipe(finalize(() => { this.loading = false; }))
        .subscribe({
          next: (response) => {
            this.modalRef.close();
            // window.history.pushState(null, '');
            // window.history.forward();
            // window.history.
            // window.history.replaceState(null, '')
            this.toasterService.show('Success', MESSAGE_CONSTANTS.OTP_VERIFIED);
            this.viventUtil.setItem(response?.data?.token, 'loggedInUser');
            this.dataService.sendData({ token: response.data.token });
            // this.router.navigate(['/order'], { replaceUrl: true });
            // this.router.navigate(['/order'], { replaceUrl: true });
            this.router.navigateByUrl('/order', { replaceUrl: true });
            // this.router.navigate([`/myPage/${this.Id}`]);
          },
          error: (e) => {
            this.codeInput.reset();
          }
        })
    } else {
      this.toasterService.show("Warning", MESSAGE_CONSTANTS.NO_CODE);
    }
  }

  resendVerificationCode() {
    this.codeExpired = false;
    this.countdown.restart();
    this.ssoService.resendOtp({ id: this.id })
      .pipe(finalize(() => this.codeExpired = false))
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.toasterService.show("Success", response?.message);
          }
        },
        error: (e) => {
          this.toasterService.showError(e);
        }
      })
  }

  handleCountdownEvent(event: CountdownEvent) {
    if (event?.left === 0) {
      this.codeExpired = true;
    }
  }

  close() {
    this.modalRef.close();
    this.viventUtil.redirectToExternalUrl(environment.storeAdminUrl, "_self");
  }
}
