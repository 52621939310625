import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { USER_ROLES, VIVENT_APP_CONSTANT } from 'src/app/constants';
import {
  AuthService,
  DataService,
  OrderService,
  ToasterService,
} from 'src/app/services';
import { ViventUtil } from 'src/app/utils';
import { ConfirmationModalComponent } from '../../modals';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  config = VIVENT_APP_CONSTANT.modalConfig;
  confirmationModalRef!: MdbModalRef<ConfirmationModalComponent>;
  isAdmin: boolean = false;
  user!: any;
  orderId: string = '';
  userRoles = USER_ROLES;
  orderStatus!: string;
  showCart: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private viventUtil: ViventUtil,
    private dataService: DataService,
    private authService: AuthService,
    private orderService: OrderService,
    private toastrService: ToasterService,
    private modalService: MdbModalService
  ) {
    const { url } = this.router;
    if (url.includes('/order/create')) {
      this.showCart = true;
    } else if (url.includes('/order/edit')) {
      this.showCart = false;
    }
  }

  ngOnInit(): void {
    const token = this.viventUtil.getLoggedInUser();
    if (token) {
      const isTokenExpired = this.authService.isTokenExpired(token);
      if (isTokenExpired) {
        this.viventUtil.clearStorage();
        this.user = null;
      } else {
        this.user = this.authService.decryptToken(token);
      }
    }

    this.dataService.getData().subscribe((response: any) => {
      if (response) {
        this.user = this.authService.decryptToken(
          this.viventUtil.getLoggedInUser()
        );
      }
      if (response?.showCart === false) {
        this.showCart = response.showCart;
      }
    });
  }

  openConfirmation() {
    this.route.queryParams.subscribe((params) => {
      if (params['orderId']) {
        this.orderId = params['orderId'];
      } else {
        const route_list = this.router.url.split('/');
        this.orderId = route_list[route_list.length - 1];
      }
    });
    const config = this.viventUtil.shallowCopy(this.config);
    config.keyboard = false;
    config.modalClass = 'modal-dialog-centered';
    config.data.title = 'Confirmation';
    config.data.orderId = this.orderId;
    config.data.body =
      '<p>You have pending unsaved changes. All the changes will be discarded.</p><p>Are you sure you want to leave this page?</p>';
    config.data.confirmButtonLabel = 'Yes, Leave';
    config.data.cancelButtonLabel = 'Stay on this Page';
    this.confirmationModalRef = this.modalService.open(
      ConfirmationModalComponent,
      config
    );
    this.confirmationModalRef.onClose.subscribe((response: any) => {
      if (response) {
        const loggedInUser = this.viventUtil.getLoggedInUser();
        let deleteOrder$: Observable<any>;
        if (loggedInUser) {
          deleteOrder$ = this.orderService.deleteOrder(this.orderId);
        } else {
          deleteOrder$ = this.orderService.deleteInvalidOrder(this.orderId);
        }
        deleteOrder$.subscribe({
          next: (response) => {
            if (response.success) {
              this.toastrService.show('Success', response.message);
              this.authService.logout();
              this.viventUtil.goToStore();
            }
          },
        });
      }
    });
  }

  navigate() {
    if (this.user && this.user?.role !== USER_ROLES.CUSTOMER.toLowerCase()) {
      this.router.navigate(['/order']);
    } else {
      return;
    }
  }

  logout() {
    this.authService.logout();
    this.viventUtil.goToStore();
  }
}
