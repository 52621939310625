<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
    <div class="container-xl">
        <!-- Collapsible wrapper -->
        <div class="collapse navbar-collapse no-print" id="navbarSupportedContent">
            <!-- Navbar brand -->
            <a class="navbar-brand mt-2 mt-lg-0" role="button" (click)="navigate()">
                <img src="../../../../assets/img/svg/vivent.svg" height="40" alt="Vivent" />
            </a>
        </div>
        <ul class="navbar-nav no-print">
            <!-- Avatar -->
            <li mdbDropdown class="nav-item dropdown" *ngIf="!showCart && user && user?.role !== userRoles.CUSTOMER.toLowerCase()">
                <a class="nav-link d-flex align-items-center" id="navbarDropdownMenuLink" role="button"
                    mdbDropdownToggle aria-expanded="false">
                    <div class="d-flex justify-content-end align-items-center">
                        <a role="button" class="avatar bg-primary text-center text-white me-2">
                            <i-bs name="person-fill"></i-bs>
                        </a>
                        <span>
                            {{user?.firstName + ' ' + user?.lastName}}
                        </span>
                    </div>
                </a>
                <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item" *ngIf="showCart || user?.role === userRoles.CUSTOMER.toLowerCase()">
                <div class="d-flex justify-content-end align-items-center">
                    <a role="button" (click)="openConfirmation()">
                        <i-bs name="cart-fill" class="text-gray-dark me-1"></i-bs>
                        <span class="fw-bold">Cart</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</nav>