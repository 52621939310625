import { Injectable } from '@angular/core';
import { map, of, tap } from 'rxjs';
import { API_CONSTANTS, STORAGE_CONSTANT } from '../constants';
import { HttpClientService } from './http-client.service';
import { TransferStateService } from './transfer-state.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClientService,
    private transferStateService: TransferStateService
  ) {}

  getAllOrders(params?: any) {
    return this.http.get(API_CONSTANTS.ORDER, params).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getOrderById(orderId: any) {
    // const order = STORAGE_CONSTANT.ORDERS;
    // const cachedResponse: any = this.transferStateService.getNgState<any>(order);

    // if (!cachedResponse) {
    return this.http.get(API_CONSTANTS.ORDER + `/${orderId}`).pipe(
      map((res: any) => {
        return res;
      })
    );
    // }
    // return of(cachedResponse);
  }

  deleteOrder(orderId: string) {
    return this.http.remove(API_CONSTANTS.ORDER + `/${orderId}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteInvalidOrder(orderId: string) {
    return this.http
      .remove(API_CONSTANTS.ORDER + `/${orderId}` + API_CONSTANTS.INVALID_ORDER)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getOrderItems(params: any) {
    return this.http.get(API_CONSTANTS.ORDER_ITEM, params).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  approveOrder(orderId: any) {
    return this.http
      .patch(API_CONSTANTS.ORDER + `/${orderId}/approve`, {})
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  cancelOrder(orderId: any) {
    return this.http.patch(API_CONSTANTS.ORDER + `/${orderId}/cancel`, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // status -> Approve, Cancel
  changeStatus(orderId: any, data: any) {
    return this.http
      .patch(API_CONSTANTS.ORDER + `/${orderId}/change-status`, data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  generateShipping(orderId: any, note: string) {
    return this.http
      .post(`${API_CONSTANTS.SHIPPO}/generate-shipping-label/${orderId}`, {
        note,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  submitOrder(orderId: any) {
    return this.http.patch(API_CONSTANTS.ORDER + `/${orderId}/submit`, {}).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  orderShipmentList(query?: any) {
    return this.http.get(API_CONSTANTS.ORDER_SHIPMENT, query).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateSurvey(survey: any, orderId: string) {
    return this.http
      .patch(API_CONSTANTS.ORDER + `/${orderId}` + API_CONSTANTS.SURVEY, {
        survey,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getSurveysByOrderId(orderId: string) {
    return this.http
      .get(API_CONSTANTS.ORDER + `/${orderId}` + API_CONSTANTS.SURVEY)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getSampleReport(query?: any) {
    return this.http
      .get(`${API_CONSTANTS.ORDER}${API_CONSTANTS.REPORT}`, query)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getSurveySequence(orderId: string, query: any) {
    return this.http
      .get(
        `${API_CONSTANTS.ORDER}/${orderId}${API_CONSTANTS.SURVEY_SEQUENCE}`,
        query
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateSurveySequence(orderId: string, body: any) {
    return this.http
    .patch(
      `${API_CONSTANTS.ORDER}/${orderId}${API_CONSTANTS.SURVEY_SEQUENCE}`,
      body
    )
    .pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
