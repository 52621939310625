import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapIconsModule, pip } from "ngx-bootstrap-icons";
import { icons } from "../utils";

import * as interceptors from '../interceptors';
import * as components from '../components';
import * as directives from '../directives';
import * as services from '../services';
import * as utils from '../utils';
import * as pipes from '../pipes';
import * as guards from '../guards';

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MDBAngularModule } from "./mdb-angular.module";

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BrowserTransferStateModule } from "@angular/platform-browser";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { CountdownModule } from "ngx-countdown";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@NgModule({
    declarations: [
        directives.DebounceClickDirective,

        // pipes.CustomFilterPipe,
        pipes.FormatPhoneNumberPipe,
        pipes.SafePipe,

        components.HeaderComponent,
        components.FooterComponent,
        components.SelectComponent,
        components.RadioComponent,
        components.InputComponent,
        components.DatepickerComponent,
        components.AutocompleteComponent,
        components.LoaderComponent,
        components.SkeletonLoaderComponent,
        components.ConfirmationModalComponent,
        components.ShippingDetailComponent,
        components.OrderCancellationModalComponent,
        components.GenerateLabelModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserTransferStateModule,
        NgxBootstrapIconsModule.pick(icons),
        RouterModule.forChild([]),
        MDBAngularModule,
        NgxMaskModule.forRoot(),
        YouTubePlayerModule,
        CountdownModule,
        NgxSkeletonLoaderModule
    ],
    exports: [
        directives.DebounceClickDirective,

        // pipes.CustomFilterPipe,
        pipes.FormatPhoneNumberPipe,
        pipes.SafePipe,

        components.HeaderComponent,
        components.FooterComponent,
        components.SelectComponent,
        components.RadioComponent,
        components.InputComponent,
        components.DatepickerComponent,
        components.AutocompleteComponent,
        components.LoaderComponent,
        components.SkeletonLoaderComponent,
        components.ConfirmationModalComponent,
        components.ShippingDetailComponent,
        components.OrderCancellationModalComponent,
        components.GenerateLabelModalComponent,

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserTransferStateModule,
        NgxBootstrapIconsModule,
        MDBAngularModule,
        NgxMaskModule,
        YouTubePlayerModule,
        CountdownModule
    ],
    providers: [
        utils.ViventUtil,
        utils.DateUtility,
        utils.PDFUtility,
        guards.AuthGuard,
        services.HttpClientService,
        services.AuthService,
        services.ToasterService,
        services.OrderService,
        services.CustomerService,
        services.SsoService,
        services.DataService,
        services.JigsawService,
        services.ZipCodeService,
        services.TransferStateService,
        services.SurveyService,
        services.NavigationService,
        services.ActivityLogService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: interceptors.TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: interceptors.ServerErrorInterceptor,
            multi: true
        }
    ],
})
export class SharedModule { }