import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() data: any[] = [];
  @Input() control: any;
  @Input() controlName: string = '';
  @Input() label!: string;
  @Input() value!: any;
  @Input() name!: any;
  @Input() filter: boolean = false;
  @Input() multiple: boolean = false;
  @Input() clearButton: boolean = false;
  @Input() submitted: boolean = false;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';

  @Output() emitSelectedValue = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  getValue(option: any) {
    return option[this.value];
  }

  getOption(option: any) {
    return option[this.name];
  }

  handleChange(event: any) {
    const value = {
      controlName: this.controlName,
      value: event
    }
    this.emitSelectedValue.emit(value);
  }
}
