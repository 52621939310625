import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TransferStateService {

  constructor(
    private ngState: TransferState
  ) { }

  setNgState<T>(key: string, value: T) {
    const sitesKey = makeStateKey(key);
    this.ngState.set<T>(sitesKey, value);
  }

  getNgState<T>(key: string): T {
    const sitesKey = makeStateKey(key);
    return this.ngState.get<T>(sitesKey, <any>null);
  }
}
