import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, ORDER_STATUS, USER_ROLES, VIVENT_APP_CONSTANT } from 'src/app/constants';
import { AuthService, DataService, NavigationService, OrderService, ToasterService } from 'src/app/services';
import { ViventUtil } from 'src/app/utils';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-shipping-detail',
  templateUrl: './shipping-detail.component.html',
  styleUrls: ['./shipping-detail.component.scss']
})
export class ShippingDetailComponent implements OnInit {

  loading: boolean = false;
  skeletonTheme = VIVENT_APP_CONSTANT.skeletonLoaderConfig;
  orderItems: any[] = [];
  orderId: string = '';
  order!: any;
  orderProcessing: boolean = false;
  user !: any;
  orderButtonText: string = 'Complete your Order';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private viventUtil: ViventUtil,
    private authService: AuthService,
    private dataService: DataService,
    private orderService: OrderService,
    private toasterService: ToasterService,
    private navigationService: NavigationService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.user = this.authService.decryptToken(this.viventUtil.getLoggedInUser());
  
    this.route.queryParams.subscribe(params => {
      if (params['orderId']) {
        this.orderId = params['orderId'];
        this.getOrderById();
      } else {
        this.router.navigate(['invalid-url'], {
          queryParams: {
            module: 'shipping-detail'
          },
          state: {
            titleText: 'Invalid URL',
            errorText: MESSAGE_CONSTANTS.ORDER_NOT_FOUND,
            goBack: false
          }
        })
      }
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Order Confirmation');
    this.metaService.updateTag({ name: 'title', content: 'Order Confirmation' });
  }

  getOrderById() {
    this.loading = true;
    this.orderService.getOrderById(this.orderId)
      .pipe(finalize(() => { this.loading = false }))
      .subscribe({
        next: (response: any) => {
          this.order = response.data;
          const { url } = this.router;
          if (response?.data?.customer) {
            const { status } = response.data;
            if ((url.includes('/shipping-detail') && status.toLowerCase() !== ORDER_STATUS.CREATED.toLowerCase())) {
              this.router.navigate(['invalid-url'], {
                state: {
                  titleText: 'Invalid URL',
                  errorText: MESSAGE_CONSTANTS.ORDER_ALREADY_CREATED,
                  goBack: false
                }
              });
            }
          }
        },
        error: (e) => { }
      })
  }

  completeOrder() {
    if (this.order.status.toLowerCase() === ORDER_STATUS.WAITING_APPROVAL.toLowerCase()) {
      this.router.navigate(['/order-confirmation'], {
        relativeTo: this.route,
        skipLocationChange: true,
        state: { customer: this.order.customer },
        queryParams: { orderId: this.orderId },
      });
      return;
    }
    this.orderProcessing = true;
    this.orderService.submitOrder(this.orderId)
      .pipe(finalize(() => {
        this.loading = false;
        this.orderProcessing = false;
      }))
      .subscribe({
        next: (response: any) => {
          this.orderItems = response;
          if (response.success) {
            this.toasterService.show('Success', MESSAGE_CONSTANTS.ORDER_CREATED);
            window.history.pushState(null, '');
            this.authService.logout();
            this.dataService.sendData({ showCart: false });
            this.router.navigate(['/order-confirmation'], {
              relativeTo: this.route,
              skipLocationChange: true,
              state: { customer: this.order.customer },
              queryParams: { orderId: this.orderId },
            });
          }
        },
        error: (e) => { }
      })
  }

  edit() {
    if (this.user?.role === USER_ROLES.CUSTOMER.toLowerCase()) {
      this.router.navigate([`order/create/${this.orderId}`], {
        queryParams: {
          returnUrl: `shipping-detail`,
        },
      });
    } else {
      this.router.navigate(
        [`order/edit/${this.orderId}/customer-information`],
        {
          queryParams: {
            returnUrl: `shipping-detail`,
          },
        }
      );
    }
  }

  goBack() {
    this.navigationService.back();
  }

}
