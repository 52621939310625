import { Injectable } from "@angular/core";
import moment from 'moment';
import { VIVENT_APP_CONSTANT } from "../constants";

@Injectable()

export class DateUtility {
    public pad(n: any) { return n < 10 ? "0" + n : n; }

    public formatDate(dateTime: Date, format: string = VIVENT_APP_CONSTANT.defaultDateFormat) {
        if (dateTime) {
            return moment(dateTime).format(format);
        }
        return null;
    }

    public getDate(dateTime: string): any {
        if (dateTime) {
            return new Date(moment(dateTime).format(VIVENT_APP_CONSTANT.defaultDateFormat))
        }
        return null;
    }

    public getTime(dateTime: string): any {
        if (dateTime) {
            return moment(dateTime).format(VIVENT_APP_CONSTANT.defaultTimeFormat)
        }
        return null;
    }

    public isDateAfter(date: any): boolean {
        return moment(date).isAfter(new Date());
    }

    public getMMddyyyy(date: Date) {
        date = new Date(date);
        let result = [this.pad(date.getMonth() + 1), this.pad(date.getDate()), date.getFullYear()].join('/');
        return result;
    }

    public getMinValidDOB(): Date {
        var currentDate = new Date();
        return new Date(currentDate.setFullYear(currentDate.getFullYear() - 18));
    }

    public checkValidDOB(minDate: Date) {
        var currentDate = moment(new Date());
        return currentDate.diff(minDate, 'years') >= 18;
    }

    public getMinDate(date?: Date): Date {
        if (!date)
            date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() - 1;

        return new Date(year, month, day);
    }

    public getMaxDate(date?: Date): Date {
        if (!date)
            date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate() + 1;

        return new Date(year, month, day);
    }
}