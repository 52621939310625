import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable()

export class ViventUtil {
    public getLoggedInUser() {
        return localStorage.getItem('loggedInUser') ? JSON.parse(localStorage.getItem('loggedInUser') as any) : null;
    }

    public setLoggedInUser(loggedInUser: string) {
        return localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
    }

    public removeLoggedInUser() {
        localStorage.removeItem('loggedInUser');
    }

    public setItem(data: any, key: string) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public getItem(key: string) {
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) as any) : null;
    }

    public clearStorage() {
        localStorage.clear();
    }

    public parseNumber(number: any): number {
        return number ? parseInt(number) : 0;
    }

    public redirectToExternalUrl(url: string, action: string = '_blank'): void {
        window.open(url, action)
    }

    public goToStore() {
        this.redirectToExternalUrl(environment.storeUrl, "_self");
    }

    public goToAdminStore() {
        this.redirectToExternalUrl(environment.storeAdminUrl, "_self");
    }

    public shallowCopy(data: any) {
        return JSON.parse(JSON.stringify(data));
    }

    public getFilterParams(params?: any) {
        let filter = {};
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                Object.assign(filter, { [key]: value })
            }
        }
        return filter;
    }

    public convertBufferToBase64(buffer: any[] = []) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    convertBase64toExcel(base64Character: string) {
        var byteArray = new Uint8Array(base64Character.split("")
            .map((char) => char.charCodeAt(0)))
        const blob = new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
    }

    public downloadExcelFromArrayBuffer(arrayBuffer: ArrayBuffer, filename: string) {
        const blob = this.convertArrayBufferToBlob(arrayBuffer);
        this.forceDownload(blob, filename);
    }

    public convertArrayBufferToBlob(arrayBuffer: ArrayBuffer) {
        // Step 1: Convert the Array Buffer to a Uint8Array
        const typedArray = new Uint8Array(arrayBuffer);

        // Step 2: Create a Blob from the typed array
        const blob = new Blob([typedArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return blob;
    }

    forceDownload(blob: Blob, filename: string) {
        const href = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        Object.assign(anchor, { download: filename, href });
        anchor.click();
    }
}