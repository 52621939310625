export const STATES = [
  {
    stateName: 'Colorado',
    value: 'CO',
    zip: [],
    youtubeId: ''
  },
  {
    stateName: 'Missouri',
    value: 'MI',
    zip: [],
    youtubeId: ''
  },
  {
    stateName: 'Wisconsin',
    value: 'WI',
    zip: [],
    youtubeId: ''
  },
  {
    stateName: 'Texas',
    value: 'TX',
    zip: [],
    youtubeId: ''
  }
];