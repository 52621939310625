<div class="modal-header border-0">
    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <p class="mb-0" [innerHTML]="body"></p>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-primary" debounceClick (debounceClick)="confirm()">{{confirmButtonLabel}}</button>
    <button type="button" class="btn btn btn-gray-light" (click)="modalRef.close()">{{cancelButtonLabel}}</button>
</div>