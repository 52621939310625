import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS, MESSAGE_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ViventUtil } from '../utils';
import { Router } from '@angular/router';

const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token !: any;

  constructor(
    private router: Router,
    private viventUtil: ViventUtil,
    private http: HttpClientService
  ) { }

  public isAuthenticated(): boolean {
    this.token = this.viventUtil.getLoggedInUser();
    if (this.token) {
      const isExpired = helper.isTokenExpired(this.token);
      if (isExpired) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  decryptToken(token: string) {
    return helper.decodeToken(token);
  }

  isTokenExpired(token: string) {
    return helper.isTokenExpired(token);
  }

  login(credentials: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.LOGIN}`, credentials).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  register(body: any) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.REGISTER}`, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  verifyLoginCode(userName: string, code: string) {
    return this.http.post(`${API_CONSTANTS.AUTH}${API_CONSTANTS.VERIFY_CODE}`, {
      emailAddress: userName,
      otpCode: code
    }).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  resendLoginCode(username: string) {
    return this.http.get(`${API_CONSTANTS.AUTH}${API_CONSTANTS.RESEND_CODE}`, {
      email: username
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }
}
