<div class="modal-header border-0">
    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <div class="md-form mb-2">
        <label for="note" class="form-label">Note</label>
        <div class="input-group flex-nowrap me-3">
            <div class="input-group">
                <textarea type="text" rows="5" class="form-control px-1 bg-gray-light" name="shipping-label-note"
                    [(ngModel)]="note"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-end">
    <button type="button" class="btn btn-primary" debounceClick
        (debounceClick)="confirm()">{{confirmButtonLabel}}</button>
    <button type="button" class="btn btn btn-gray-light" (click)="modalRef.close()">{{cancelButtonLabel}}</button>
</div>