<div class="modal-header border-0">
  <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modalRef.close()"
  ></button>
</div>
<div class="modal-body">
  <p class="mb-2">{{ body }}</p>

  <ng-container *ngIf="!(isCustomField | async)">
    <app-select
      [form]="orderCancellationForm"
      [control]="orderCancellationForm.controls['note']"
      [controlName]="'note'"
      [submitted]="submitted"
      [data]="orderCancellationReasons"
      [name]="'name'"
      [value]="'value'"
      (emitSelectedValue)="receiveSelectedValue($event)"
      [required]="true"
    ></app-select>
  </ng-container>

  <ng-container *ngIf="isCustomField | async">
    <!-- <app-input
      [name]="'note'"
      [type]="'text'"
      [control]="orderCancellationForm.controls['note']"
      [submitted]="submitted"
    >
    </app-input> -->
    <app-input
      [name]="'note'"
      [type]="'text'"
      [inputGroup]="'input-group'"
      [control]="orderCancellationForm.controls['note']"
      [submitted]="submitted"
      (emitCancel)="receiveCancel($event)"
    >
    </app-input>
  </ng-container>
</div>
<div class="modal-footer justify-content-end">
  <button
    type="button"
    class="btn btn-primary"
    debounceClick
    (debounceClick)="confirm()"
    [disabled]="loading"
  >
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
      *ngIf="loading"
    ></span
    >{{ confirmButtonLabel }}
  </button>
  <button
    type="button"
    class="btn btn btn-gray-light"
    (click)="modalRef.close()"
  >
    {{ cancelButtonLabel }}
  </button>
</div>
