import { Injectable } from "@angular/core";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable()

export class PDFUtility {
    async convertToPDF(elementID: string, fileName: string, isDownload: boolean = false) {
        const element = document.getElementById(elementID);
        const elementHeight = element?.offsetHeight;
        if (element) {
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = elementHeight;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            const doc = new jsPDF('p', 'mm', "a4");
            let position = 0;
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 10);
            heightLeft -= pageHeight ?? 0;
            while (heightLeft >= 0) {
                position = (heightLeft - imgHeight) + 2;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 10);
                heightLeft -= pageHeight ?? 0;
            }
            const pdfConvertedFile = new File([doc.output('blob')], `${fileName}.pdf`);
            return pdfConvertedFile;
        }
        return null;
    }
}