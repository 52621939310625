import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor() { }

  generatePDF(targetElement: any, headerContent: any): Promise<any> {
    const element = document.getElementById(targetElement) as HTMLElement;

    return new Promise(async (resolve, reject) => {
      html2canvas(element).then(canvas => {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 180;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        let pdf = new jsPDF('p', 'mm', "a4");
        pdf.setFontSize(15);
        var img = new Image();
        img.src = headerContent.imgPath;
        pdf.addImage(img, 'PNG', 80, 10, 0, 10, '', 'FAST');
        pdf.addImage(imgData, 'PNG', 15, 30, imgWidth, imgHeight + 10);

        pdf.setDrawColor(214, 214, 214);
        resolve(pdf)
      }).catch(e => reject(e))
    });
  }
}
