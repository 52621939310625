<div class="modal-header border-0">
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">

  <div class="text-center">
    <h3 class="mb-2" id="exampleModalLabel">Verify OTP</h3>
    <small class="d-block blue-grey-text">A 6-digit code has been sent to <span class="font-weight-bold">{{ username
        }}</span></small>
    <div class="row mt-4">
      <div class="col-8 mx-auto">
        <code-input #codeInput [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
          (codeCompleted)="onCodeCompleted($event)">
        </code-input>
        <p class="mt-3 mb-0 small" [class.d-none]="codeExpired">The code is valid for <span class="text-dark">
            <countdown #cd [config]="{ leftTime: timeValidity, format: 'm:ss' }" (event)="handleCountdownEvent($event)">
            </countdown> minutes
          </span></p>
      </div>
    </div>
    <p class="mt-3 mb-4">{{ !codeExpired ? 'Didn\'t receive the code?' : 'Code has expired. Request for a new one.' }}
      <a class="text-primary" debounceClick (debounceClick)="resendVerificationCode()">Resend</a>
    </p>

  </div>
</div>