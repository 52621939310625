import {
    person,
    eye,
    eyeSlash,
    eyeSlashFill,
    lockFill,
    search,
    plusCircle,
    dashCircle,
    phoneFill,
    envelopeFill,
    threeDotsVertical,
    personFill,
    chevronDown,
    chevronLeft,
    checkCircleFill,
    filter,
    cartFill,
    printerFill,
    emojiFrown,
    fileEarmarkExcelFill,
    infoCircleFill
} from 'ngx-bootstrap-icons';

export const icons = {
    person,
    eye,
    eyeSlash,
    eyeSlashFill,
    lockFill,
    search,
    plusCircle,
    dashCircle,
    phoneFill,
    envelopeFill,
    threeDotsVertical,
    personFill,
    chevronDown,
    chevronLeft,
    checkCircleFill,
    filter,
    cartFill,
    printerFill,
    emojiFrown,
    fileEarmarkExcelFill,
    infoCircleFill
}