import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { USER_ROLES } from 'src/app/constants';
import { AuthService } from 'src/app/services';
import { ViventUtil } from 'src/app/utils';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  invalidProperties: any = {};

  constructor(
    private router: Router,
    private viventUtil: ViventUtil,
    private authService: AuthService
  ) { 
    const user = this.authService.decryptToken(this.viventUtil.getLoggedInUser());
    if (user && user?.role.toLowerCase() === USER_ROLES.ADMINISTRATOR.toLowerCase()) {
      this.router.navigate(['/order'])
    }
  }

  ngOnInit(): void {
    this.invalidProperties = window.history.state;
  }

  goToStore() {
    this.viventUtil.goToStore();
  }

}
