export const API_CONSTANTS = {
    AUTH: '/auth',
    LOGIN: '/login',
    REGISTER: '/register',
    VERIFY_CODE: '/verify',
    RESEND_CODE: '/resend-otp',
    ORDER: '/order',
    INVALID_ORDER: '/invalid-order',
    ORDER_ITEM: '/order-item',
    ORDER_SHIPMENT: '/order-shipment',
    CUSTOMER: '/customer',
    SHIPPO: '/shippo',
    SSO: '/sso',
    SSO_USER: '/sso-user',
    VERIFY_USER: '/verify-user',
    VERIFY_OTP: '/verify-otp',
    ZIP_CODE: '/zip-code',
    STATE_OPTION: '/state-option',
    VERIFY_ZIP: '/verify-zip',
    SURVEY: '/survey',
    SURVEY_FORM: '/survey-form',
    SURVEY_SEQUENCE: '/survey-sequence',
    REPORT: '/report',
    ACTIVITY_LOG: '/activity-log',
    VIDEO_SURVEY: '/video-survey',
  };
  