<ng-container *ngIf="!inputGroup">
  <input type="{{ type }}" class="{{ class }}" id="{{ name }}"
  [formControl]="control" mask="{{ mask }}" maxlength="{{ maxLength }}"
  minlength="{{ minLength }}" placeholder="{{ placeholder }}"
  [class.is-invalid]=" (control?.dirty || submitted) && required &&
  control?.invalid " />
  <!-- [class.is-invalid]="submitted && required && control?.invalid"> -->
</ng-container>

<ng-container *ngIf="inputGroup === 'input-group'">
  <div class="{{ inputGroup }}">
    <input
      type="{{ type }}"
      class="{{ class }}"
      id="{{ name }}"
      [formControl]="control"
      mask="{{ mask }}"
      maxlength="{{ maxLength }}"
      minlength="{{ minLength }}"
      placeholder="{{ placeholder }}"
      [class.is-invalid]="
        (control?.dirty || submitted) && required && control?.invalid
      "
    />
    <span class="input-group-text" (click)="cancel()">x</span>
  </div>
</ng-container>
