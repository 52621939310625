export const US_STATES = [
    // {
    //     "name": "ALABAMA",
    //     "value": "AL",
    //     "label": "AL"
    // },
    // {
    //     "name": "ALASKA",
    //     "value": "AK",
    //     "label": "AK"
    // },
    // {
    //     "name": "AMERICAN SAMOA",
    //     "value": "AS",
    //     "label": "AS"
    // },
    // {
    //     "name": "ARIZONA",
    //     "value": "AZ",
    //     "label": "AZ"
    // },
    // {
    //     "name": "ARKANSAS",
    //     "value": "AR",
    //     "label": "AR"
    // },
    // {
    //     "name": "CALIFORNIA",
    //     "value": "CA",
    //     "label": "CA"
    // },
    {
        "name": "Colorado",
        "value": "CO",
        "label": "CO"
    },
    // {
    //     "name": "CONNECTICUT",
    //     "value": "CT",
    //     "label": "CT"
    // },
    // {
    //     "name": "DELAWARE",
    //     "value": "DE",
    //     "label": "DE"
    // },
    // {
    //     "name": "DISTRICT OF COLUMBIA",
    //     "value": "DC",
    //     "label": "DC"
    // },
    // {
    //     "name": "FEDERATED STATES OF MICRONESIA",
    //     "value": "FM",
    //     "label": "FM"
    // },
    // {
    //     "name": "FLORIDA",
    //     "value": "FL",
    //     "label": "FL"
    // },
    // {
    //     "name": "GEORGIA",
    //     "value": "GA",
    //     "label": "GA"
    // },
    // {
    //     "name": "GUAM",
    //     "value": "GU",
    //     "label": "GU"
    // },
    // {
    //     "name": "HAWAII",
    //     "value": "HI",
    //     "label": "HI"
    // },
    // {
    //     "name": "IDAHO",
    //     "value": "ID",
    //     "label": "ID"
    // },
    // {
    //     "name": "ILLINOIS",
    //     "value": "IL",
    //     "label": "IL"
    // },
    // {
    //     "name": "INDIANA",
    //     "value": "IN",
    //     "label": "IN"
    // },
    // {
    //     "name": "IOWA",
    //     "value": "IA",
    //     "label": "IA"
    // },
    // {
    //     "name": "KANSAS",
    //     "value": "KS",
    //     "label": "KS"
    // },
    // {
    //     "name": "KENTUCKY",
    //     "value": "KY",
    //     "label": "KY"
    // },
    // {
    //     "name": "LOUISIANA",
    //     "value": "LA",
    //     "label": "LA"
    // },
    // {
    //     "name": "MAINE",
    //     "value": "ME",
    //     "label": "ME"
    // },
    // {
    //     "name": "MARSHALL ISLANDS",
    //     "value": "MH",
    //     "label": "MH"
    // },
    // {
    //     "name": "MARYLAND",
    //     "value": "MD",
    //     "label": "MD"
    // },
    // {
    //     "name": "MASSACHUSETTS",
    //     "value": "MA",
    //     "label": "MA"
    // },
    // {
    //     "name": "MICHIGAN",
    //     "value": "MI",
    //     "label": "MI"
    // },
    // {
    //     "name": "MINNESOTA",
    //     "value": "MN",
    //     "label": "MN"
    // },
    // {
    //     "name": "MISSISSIPPI",
    //     "value": "MS",
    //     "label": "MS"
    // },
    {
        "name": "Missouri",
        "value": "MO",
        "label": "MO"
    },
    // {
    //     "name": "MONTANA",
    //     "value": "MT",
    //     "label": "MT"
    // },
    // {
    //     "name": "NEBRASKA",
    //     "value": "NE",
    //     "label": "NE"
    // },
    // {
    //     "name": "NEVADA",
    //     "value": "NV",
    //     "label": "NV"
    // },
    // {
    //     "name": "NEW HAMPSHIRE",
    //     "value": "NH",
    //     "label": "NH"
    // },
    // {
    //     "name": "NEW JERSEY",
    //     "value": "NJ",
    //     "label": "NJ"
    // },
    // {
    //     "name": "NEW MEXICO",
    //     "value": "NM",
    //     "label": "NM"
    // },
    // {
    //     "name": "NEW YORK",
    //     "value": "NY",
    //     "label": "NY"
    // },
    // {
    //     "name": "NORTH CAROLINA",
    //     "value": "NC",
    //     "label": "NC"
    // },
    // {
    //     "name": "NORTH DAKOTA",
    //     "value": "ND",
    //     "label": "ND"
    // },
    // {
    //     "name": "NORTHERN MARIANA ISLANDS",
    //     "value": "MP",
    //     "label": "MP"
    // },
    // {
    //     "name": "OHIO",
    //     "value": "OH",
    //     "label": "OH"
    // },
    // {
    //     "name": "OKLAHOMA",
    //     "value": "OK",
    //     "label": "OK"
    // },
    // {
    //     "name": "OREGON",
    //     "value": "OR",
    //     "label": "OR"
    // },
    // {
    //     "name": "PALAU",
    //     "value": "PW",
    //     "label": "PW"
    // },
    // {
    //     "name": "PENNSYLVANIA",
    //     "value": "PA",
    //     "label": "PA"
    // },
    // {
    //     "name": "PUERTO RICO",
    //     "value": "PR",
    //     "label": "PR"
    // },
    // {
    //     "name": "RHODE ISLAND",
    //     "value": "RI",
    //     "label": "RI"
    // },
    // {
    //     "name": "SOUTH CAROLINA",
    //     "value": "SC",
    //     "label": "SC"
    // },
    // {
    //     "name": "SOUTH DAKOTA",
    //     "value": "SD",
    //     "label": "SD"
    // },
    // {
    //     "name": "TENNESSEE",
    //     "value": "TN",
    //     "label": "TN"
    // },
    {
        "name": "Texas",
        "value": "TX",
        "label": "TX"
    },
    // {
    //     "name": "UTAH",
    //     "value": "UT",
    //     "label": "UT"
    // },
    // {
    //     "name": "VERMONT",
    //     "value": "VT",
    //     "label": "VT"
    // },
    // {
    //     "name": "VIRGIN ISLANDS",
    //     "value": "VI",
    //     "label": "VI"
    // },
    // {
    //     "name": "VIRGINIA",
    //     "value": "VA",
    //     "label": "VA"
    // },
    // {
    //     "name": "WASHINGTON",
    //     "value": "WA",
    //     "label": "WA"
    // },
    // {
    //     "name": "WEST VIRGINIA",
    //     "value": "WV",
    //     "label": "WV"
    // },
    {
        "name": "Wisconsin",
        "value": "WI",
        "label": "WI"
    },
    // {
    //     "name": "WYOMING",
    //     "value": "WY",
    //     "label": "WY"
    // }
]