import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MESSAGE_CONSTANTS, USER_ROLES } from '../constants';
import { AuthService } from '../services';
import { ViventUtil } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private viventUtil: ViventUtil,
    private authService: AuthService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const loggedInUser = this.authService.decryptToken(this.viventUtil.getLoggedInUser());
      if (this.authService.isAuthenticated() && loggedInUser?.role !== USER_ROLES.CUSTOMER.toLowerCase()) {
        // logged in so return true
        return true;
      }
      this.router.navigate(['not-authorized'], {
        state: {
          titleText: 'Not Authorized',
          errorText: MESSAGE_CONSTANTS.UNAUTHORIZED,
          goBack: false
        }
      });
    return false;
  }
  
}
