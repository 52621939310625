import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ViventUtil } from "../utils";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private viventUtil: ViventUtil
    ) { }
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: any = this.viventUtil.getLoggedInUser();
        let authorization = '';
        if (token) authorization = token;
        return next.handle(httpRequest.clone({ setHeaders: { authorization } }));
    }
}