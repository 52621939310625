import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() label = '';
  @Input() name = '';
  @Input() type = '';
  @Input() class = 'form-control';
  @Input() submitted = false;
  @Input() control: any;
  @Input() mask: any;
  @Input() pattern: any;
  @Input() maxLength!: number;
  @Input() minLength!: number;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() inputGroup: string = '';
  // @Input() readonly: boolean = true;
  @Output() emitCancel = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  cancel() {
    this.emitCancel.emit({
      action: 'select'
    })
  }
}
