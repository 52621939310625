const VIVENT_APP_CONSTANT = {
    userIdleTime: 1800,
    timeout: 30,
    showLimits: [
        { label: 'Show 10', value: 10 }, 
        { label: 'Show 20', value: 20 }, 
        { label: 'Show 25', value: 25 }, 
        { label: 'Show 50', value: 50 },
        { label: 'Show all', value: 'all' },
    ],
    defaultDate: '01/01/1900',
    defaultDatePipeFormat: 'MM/dd/yyyy',
    defaultDateFormat: 'MM/dd/YYYY',
    defaultTimeFormat: 'hh:mm a',
    defaultDateTimeFormat: 'MM/dd/yyyy - hh:mm a',
    defaultSubmitDateFormat: 'YYYY-MM-DD',
    mdbDefaultDateFormat: 'mm/dd/yyyy',
    modalConfig: {
        animation: true,
        backdrop: true,
        data: {
            title: '',
            body: '',
            formData: {},
            confirmButtonLabel: ''
        },
        ignoreBackdropClick: true,
        keyboard: true,
        modalClass: 'modal-lg',
    },
    confirmPopConfig: {
        position: 'left',
        data: {
            title: '',
            body: '',
            confirmButtonLabel: ''
        }
    },
    skeletonLoaderConfig: {
        'border-radius': '0',
        'margin': '0px'
    },
    inputLimit: {
        priceSeparatorLimit: '99999999999999',
        personNameMask: "S* S*",
    },
    phonePattern: { '0': { pattern: new RegExp('^[0-9]*$') } },
    passwordPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@@$%^&*-]).{8,}$/,
    phoneMask: '(000) 000-0000',
    emailPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
    mdbDatePattern: '^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$',
    zipPattern: /^[0-9]{5}$/,
    defaultLimit: 50,
    defaultOffset: 0,
    poBox: '53201',
    sortByDefault: 'asc',
    placeholder: 'Please Select',
    debounceTime: 300,
    defaultOrderId: '4ba499caa0cd457cbcec0fabd07fb424',
    ytSkipTime: 15
}

const ORDER_STATUS = {
    COMPLETED: 'Completed',
    SUBMITTED: 'Submitted',
    APPROVED: 'Approved',
    PROCESSING: 'Processing',
    RETURNED: 'Returned',
    CLOSED: 'Closed',
    CANCELLED: 'Cancelled',
    DELIVERED: 'Delivered',
    SHIPPED: 'Shipped',
    CREATED: 'Created',
    DELIVERY_IN_PROGRESS: 'Delivery in Progress',
    READY_TO_BE_SHIPPED: 'Ready to be Shipped',
    READY_FOR_SHIPPING: 'Ready for Shipping',
    WAITING_APPROVAL: 'Waiting for Approval'
}

const ETHNICITY = [
    {
        name: 'Hispanic or Latino / Latina / LatinX',
        value: 'Hispanic or Latino / Latina / LatinX'
    },
    {
        name: 'Not Hispanic or Latino / Lantina / LatinX',
        value: 'Not Hispanic or Latino / Lantina / LatinX'
    },
    {
        name: 'Prefer not to say',
        value: 'Prefer not to say'
    }
];

const RACE = [
    {
        name: 'White or Caucasian',
        value: 'White or Caucasian'
    },
    {
        name: 'Black or African American',
        value: 'Black or African American'
    },
    {
        name: 'Asian or Asian American',
        value: 'Asian or Asian American'
    },
    {
        name: 'American Indian or Alaska Native',
        value: 'American Indian or Alaska Native'
    },
    {
        name: 'Native Hawaiian or Pacific Islander',
        value: 'Native Hawaiian or Pacific Islander'
    },
    {
        name: 'Hispanic/Latinx',
        value: 'Hispanic/Latinx'
    },
    {
        name: 'Multiracial',
        value: 'Multiracial'
    },
    {
        name: 'Prefer not to say',
        value: 'Prefer not to say'
    },
];

const SOURCES = [
    {
        name: 'Friend / Family Referral',
        value: 'Friend / Family Referral'
    },
    {
        name: 'Doctor Referral',
        value: 'Doctor Referral'
    },
    {
        name: 'TV Ad',
        value: 'TV Ad'
    },
    {
        name: 'Radio',
        value: 'Radio'
    },
    {
        name: 'Billboard',
        value: 'Billboard'
    },
    {
        name: 'Online Ad',
        value: 'Online Ad'
    },
    {
        name: 'Social Media',
        value: 'Social Media'
    },
    {
        name: 'This Website',
        value: 'This Website'
    },
    {
        name: 'Google or Other Search Engine',
        value: 'Google or Other Search Engine'
    },
    {
        name: 'Other',
        value: 'Other'
    },
];

const SEX = [
    {
        name: 'Male',
        value: 'Male'
    },
    {
        name: 'Female',
        value: 'Female'
    },
    {
        name: 'Prefer not to say',
        value: 'Prefer not to say'
    }
];

const CURRENT_GENDER_IDENTITY = [
    {
        name: 'Female',
        value: 'Female',
    },
    {
        name: 'Male',
        value: 'Male',
    },
    {
        name: 'Queer',
        value: 'Queer',
    },
    {
        name: 'Transgender - Female to Male',
        value: 'Transgender - Female to Male',
    },
    {
        name: 'Transgender - Male to Female',
        value: 'Transgender - Male to Female',
    },
    {
        name: 'Another Gender',
        value: 'Another Gender',
    },
    {
        name: 'Decline to answer',
        value: 'Decline to answer',
    },
];

const ORDER_STATUSES = [
    {
        name: 'Waiting for Approval',
        value: 'Waiting for Approval'
    },
    {
        name: 'Ready to be Shipped',
        value: 'Ready to be Shipped'
    },
    {
        name: 'Shipped',
        value: 'Shipped'
    },
    {
        name: 'Delivery in Progress',
        value: 'Delivery in Progress'
    },
    {
        name: 'Delivered',
        value: 'Delivered'
    },
    {
        name: 'Cancelled',
        value: 'Cancelled'
    },
];

const TIMELINE_STATUSES = [
    {
        name: '',
        value: ''
    },
    {
        name: 'Waiting for Approval',
        value: 'Waiting for Approval'
    },
    {
        name: 'Ready to be Shipped',
        value: 'Ready to be Shipped',
        hideOnCancellation: true
    },
    {
        name: 'Cancelled',
        value: 'Cancelled'
    },
    {
        name: 'Shipped',
        value: 'Shipped',
        hideOnCancellation: true
    },
    {
        name: 'Delivered',
        value: 'Delivered',
        hideOnCancellation: true
    },
    {
        name: '',
        value: ''
    },
];

const ROLES = [
    { name: 'ADMIN', value: 'admin' },
    { name: 'USER', value: 'user' }
];

const ORDER_CANCELLATION_REASONS = [
    { name: 'Selected wrong item', value: 'Selected wrong item.' },
    { name: 'Ordered from somewhere else', value: 'Ordered from somewhere else.' },
    { name: 'Incorrect shipping address', value: 'Incorrect shipping address.' },
    { name: 'Item no longer available', value: 'Item no longer available.' },
    { name: 'Item count reached', value: 'Item count reached.' },
    { name: 'Custom field', value: '' },
]

export { VIVENT_APP_CONSTANT, ORDER_STATUS, ETHNICITY, RACE, SOURCES, SEX, ORDER_STATUSES, ROLES, TIMELINE_STATUSES, ORDER_CANCELLATION_REASONS, CURRENT_GENDER_IDENTITY };