export const MESSAGE_CONSTANTS = {
    UNAUTHORIZED: "User not authorized.",
    BAD_REQUEST: "Invalid payload format.",
    FORBIDDEN: "Forbidden.",
    SERVER_ERROR: "Server error. Please contact admin.",
    TIMEOUT: "Request timed out. Please contact admin.",
    
    // OTP CODE
    OTP_VERIFIED: "OTP Verified.",
    NO_CODE: "OTP code was not entered. Please enter OTP code to verify.",

    // SHIPPING
    SHIPPING_GENERATED: "Shipping generated successfully.",

    // ORDER
    ORDER_CREATED: "Order created successfully.",
    ORDER_APPROVED: "Order approved successfully.",
    ORDER_CANCELLED: "Order cancelled successfully.",
    ORDER_ALREADY_CREATED: "Order already created. Try creating a new order.",
    ORDER_NOT_FOUND: "No order found for the provided URL.",
    ORDER_INVALID: "Cannot continue. The order is placed for multiple states.",

    // SURVEY
    SURVEY_NOT_FOUND: "No survey available for the provided URL.",
    VIDEO_SURVEY_NOT_FOUND: "No video survey available for the provided URL.",
    NOT_AUTHORIZED: "User not authorized. Create an order to continue.",

    // ZIP
    INVALID_ZIP_CODE: "The entered zip code is invalid for the state.",

    // CUSTOMER
    CUSTOMER_INFORMATION_UPDATED: "Your information updated successfully.",
    CUSTOMER_UPDATED: "Customer information updated successfully.",

    // OTP
    VERIFICATION_CANCELLED: "Verification was cancelled by the user. Please try again.",

    // SESSION
    SESSION_EXPIRED: "Session expired.",

    INVALID_URL: "The requested url is invalid." 
}