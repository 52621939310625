<div [formGroup]="form">
    <mdb-form-control class="border-0">
        <mdb-select class="position-relative" [clearButton]="clearButton" [filter]="filter" [multiple]="multiple"
            [placeholder]="placeholder" [formControl]="control" (valueChange)="handleChange($event)"
            [class.is-invalid]="submitted && required && control?.invalid" [required]="required">
            <mdb-option *ngFor="let option of data" [value]="getValue(option)">
                {{
                getOption(option)
                }}
            </mdb-option>
        </mdb-select>
    </mdb-form-control>
</div>