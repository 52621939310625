import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {

  constructor(
    private http: HttpClientService
  ) { }

  create(body: any) {
    return this.http.post(API_CONSTANTS.ACTIVITY_LOG, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
