import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { BehaviorSubject, finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, ORDER_CANCELLATION_REASONS } from 'src/app/constants';
import { OrderService, ToasterService } from 'src/app/services';

@Component({
  selector: 'app-order-cancellation-modal',
  templateUrl: './order-cancellation-modal.component.html',
  styleUrls: ['./order-cancellation-modal.component.scss']
})
export class OrderCancellationModalComponent implements OnInit {

  title!: string;
  body!: string;
  confirmButtonLabel: string = 'Confirm';
  cancelButtonLabel: string = 'Cancel';
  formData: any = {};
  submitted = false;
  loading = false;
  orderId: any;
  orderCancellationForm !: FormGroup;
  orderCancellationReasons = ORDER_CANCELLATION_REASONS;
  isCustomField = new BehaviorSubject<boolean>(false)
  constructor(
    private formBuiler: FormBuilder,
    private orderService: OrderService,
    private toasterService: ToasterService,
    public modalRef: MdbModalRef<OrderCancellationModalComponent>,
  ) {
    this.setOrderCancellationForm();
  }

  ngOnInit(): void {
    this.orderId = this.formData?.orderId;
  }

  setOrderCancellationForm() {
    this.orderCancellationForm = this.formBuiler.group({
      status: ['Cancelled', Validators.required],
      note: ['None']
    })
  }

  confirm() {
    this.loading = true;
    if (!this.orderCancellationForm.get('note')?.value) {
      this.orderCancellationForm.patchValue({
        note: 'None'
      });
    }

    if (this.orderCancellationForm.valid) {
      this.orderService.changeStatus(this.orderId, this.orderCancellationForm.value)
        .pipe(finalize(() => { this.loading = false }))
        .subscribe({
          next: (response: any) => {
            this.modalRef.close(response.data);
            this.toasterService.show('Success', MESSAGE_CONSTANTS.ORDER_CANCELLED);
          },
          error: (e) => { }
        });
    }
  }

  receiveSelectedValue(event: any) {
    if(!event.value) {
      this.isCustomField.next(true)
      return;
    }
    this.orderCancellationForm.patchValue({
      [event.controlName]: event.value ?? ''
    });
  }

  receiveCancel(event: any) {
    if (event?.action === 'select') {
      this.isCustomField.next(false);
      this.setOrderCancellationForm();
    }
  }
}
