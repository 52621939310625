<div class="container-xl mt-3">
    <div class="row">
        <div class="col-6 mx-auto">
            <div class="alert alert-success-light text-success border mb-3" role="alert">
                <i-bs name="check-circle-fill" class="me-1"></i-bs>
                <ng-container *ngIf="!loading">{{
                    order?.customer?.firstName + ' ' +
                    order?.customer?.lastName
                    }}! </ng-container>Thank you for completing the survey.
            </div>

            <div class="row mb-2">
                <h6 class="mb-3">Order Shipping Details</h6>

                <div class="col-6 flex-column">
                    <label class="mb-1">Shipped from</label>
                    <ng-container *ngIf="!loading; then isOrderLoaded; else orderLoading"></ng-container>
                    <ng-template #orderLoading>
                        <div>
                            <p class="text-dark fw-bold mb-0">
                                <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                            </p>
                            <p class="text-dark text-nowrap mb-0">
                                <span><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></span><br />
                                <span><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></span>
                            </p>
                            <p class="text-dark mb-0"><app-skeleton-loader
                                    [config]="skeletonTheme"></app-skeleton-loader></p>
                        </div>
                    </ng-template>
                    <ng-template #isOrderLoaded>
                        <div>
                            <p class="text-dark fw-bold mb-0">Vivent Health</p>
                            <p class="text-dark text-nowrap mb-0">
                                    <span class="text-dark mb-0">{{(order?.fromAddress?.addressline1?order?.fromAddress?.addressline1:'') +
                                        (order?.fromAddress?.addressline2
                                        ? ', ' : ' ') + (order?.fromAddress?.addressline2?order?.fromAddress?.addressline2:'')}}</span><br />
                                    <span class="text-dark mb-0">{{(order?.fromAddress?.city?order?.fromAddress?.city + ', ' :'')  +
                                        (order?.fromAddress?.state?order?.fromAddress?.state :'')+ ' ' +
                                        (order?.fromAddress?.zip?order?.fromAddress?.zip:'')}}</span>
                            </p>
                        </div>
                    </ng-template>
                </div>
                <div class="col-6">
                   
                    <div class="d-flex justify-content-between">
                        <label class="mb-1">Shipped to</label>
                        <a role="button" *ngIf="!loading" class="text-primary" (click)="edit()">Edit</a>
                    </div>
                    <ng-container *ngIf="!loading; then isLoaded; else notLoaded"></ng-container>
                    <ng-template #notLoaded>
                        <div>
                            <p class="text-dark fw-bold mb-0">
                                <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
                            </p>
                            <p class="text-dark text-nowrap mb-0">
                                <span><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></span><br />
                                <span><app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader></span>
                            </p>
                            <p class="text-dark mb-0"><app-skeleton-loader
                                    [config]="skeletonTheme"></app-skeleton-loader></p>
                        </div>
                    </ng-template>
                    <ng-template #isLoaded>
                        <div>
                            <p class="text-dark fw-bold mb-0">{{order?.customer?.firstName + ' ' +
                                order?.customer?.lastName}}</p>
                            <p class="text-dark text-nowrap mb-0">
                                <span>{{ order?.address?.addressline1 + ' ' +
                                    order?.address?.addressline2}}</span><br />
                                <span>{{ order?.address?.city + ', ' + order?.address?.state + ' ' + order?.address?.zip
                                    }}</span>
                            </p>
                            <p class="text-dark mb-0">{{order?.customer?.phone | formatPhoneNumber}}</p>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="row">
                <p class="text-gray mb-0">
                    Note : All of our shipped products will be sent in a non-descript box or envelope
                </p>
            </div>
        </div>
    </div>

    <div class="separator position-relative w-100 my-4">
    </div>

    <div class="row mb-4">
        <div class="col-6 mx-auto">
            <h6 class="mb-1">Order Summary</h6>

            <div class="table-responsive px-0">
                <table class="table align-middle">
                    <thead>
                        <tr>
                            <th scope="col" class="fw-bold">Product Name</th>
                            <th scope="col" class="fw-bold text-center">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let order of order?.orderItems;">
                            <tr>
                                <td>
                                    <div [innerHTML]="order?.name"></div>
                                </td>
                                <td class="text-center">
                                    {{order?.quantity}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-6 mx-auto">
            <div class="d-flex justify-content-between align-items-center">
                <a role="button" class="text-primary" (click)="goBack()">
                    <i-bs name="chevron-left"></i-bs> Back
                </a>
                <button type="button" class="btn btn-primary" (click)="completeOrder()" [disabled]="orderProcessing">
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"
                        *ngIf="orderProcessing"></span>Complete your order</button>
            </div>
        </div>
    </div>
</div>