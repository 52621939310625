import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClientService
  ) { }

  create(body: any) {
    return this.http.post(API_CONSTANTS.CUSTOMER, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  update(body: any, customerId: any) {
    return this.http.put(API_CONSTANTS.CUSTOMER + `/${customerId}`, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
