import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '',  redirectTo: '/order', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./modules/layout-public.module').then(m => m.LayoutPublicModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/layout-secure.module').then(m => m.LayoutSecureModule),
  },
  { path: "**", redirectTo: "/not-found" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
