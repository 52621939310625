import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ViventUtil } from 'src/app/utils';

@Component({
  selector: 'app-generate-label-modal',
  templateUrl: './generate-label-modal.component.html',
  styleUrls: ['./generate-label-modal.component.scss']
})
export class GenerateLabelModalComponent implements OnInit {

  title!: string;
  body!: string;
  confirmButtonLabel: string = 'Confirm';
  cancelButtonLabel: string = 'Cancel';
  formData: any = {};
  submitted = false;
  note: string = '';

  constructor(
    private viventUtil: ViventUtil,
    public modalRef: MdbModalRef<GenerateLabelModalComponent>
    ) { }

  ngOnInit(): void {
  }

  confirm() {
    const formData = this.viventUtil.shallowCopy(this.formData);
    formData.note = this.note;
    this.modalRef.close(formData);
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

}
