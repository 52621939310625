import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VIVENT_APP_CONSTANT } from 'src/app/constants';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  
  @Input() form!: FormGroup;
  @Input() label = '';
  @Input() name = '';
  @Input() class = 'form-control';
  @Input() submitted = false;
  @Input() control: any;
  @Input() controlName: any;
  @Input() pattern: any;
  @Input() format: string = VIVENT_APP_CONSTANT.mdbDefaultDateFormat;
  @Input() maxDate!: Date;
  @Input() placeholder: string = VIVENT_APP_CONSTANT.mdbDefaultDateFormat;
  @Output() emitDateChanged = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  changeDate(event: any) {
    const value = {
      controlName: this.controlName,
      value: event
    }
    this.emitDateChanged.emit(value);
  }

}
