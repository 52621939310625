import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = []

  constructor(
    private router: Router, 
    private location: Location,
    private route: ActivatedRoute,
    ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    });
  }

  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.location.back()
    }
  }

  createURLTree(navigationUrl: string = '', queryParams?: any) {
    const url = this.router.createUrlTree([navigationUrl], {
      relativeTo: this.route, queryParams
    }).toString();

    this.location.go(url);
  }
}
