import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";

import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbAutocompleteModule } from 'mdb-angular-ui-kit/autocomplete';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        MdbDropdownModule,
        MdbCollapseModule,
        MdbSelectModule,
        MdbDatepickerModule,
        MdbAutocompleteModule,
        MdbAccordionModule,
        MdbNotificationModule,
        MdbModalModule,
        MdbTooltipModule
    ],
    exports: [
        CommonModule,
        MdbDropdownModule,
        MdbCollapseModule,
        MdbSelectModule,
        MdbDatepickerModule,
        MdbAutocompleteModule,
        MdbAccordionModule,
        MdbNotificationModule,
        MdbModalModule,
        MdbTooltipModule
    ]
})
export class MDBAngularModule { }