import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  title!: string;
  body!: string;
  confirmButtonLabel: string = 'Confirm';
  cancelButtonLabel: string = 'Cancel';
  formData: any = {};
  submitted = false;

  constructor(public modalRef: MdbModalRef<ConfirmationModalComponent>) { }

  ngOnInit(): void {
  }

  confirm() {
    this.modalRef.close(this.formData)
  }

  close() {
    this.submitted = false;
    this.modalRef.close();
  }

}
