import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(
    private http: HttpClientService
  ) { }

  setSsoUser(ssoUser: any) {
    return this.http.post(`${API_CONSTANTS.SSO_USER}`, ssoUser).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  verifySsoUser(token: string) {
    return this.http.post(`${API_CONSTANTS.SSO_USER}${API_CONSTANTS.VERIFY_USER}`, { token }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  verifySsoOtp(data: any) {
    return this.http.post(`${API_CONSTANTS.SSO_USER}${API_CONSTANTS.VERIFY_OTP}`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  resendOtp(data: any) {
    return this.http.post(`${API_CONSTANTS.SSO_USER}${API_CONSTANTS.RESEND_CODE}`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
