import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { API_CONSTANTS } from '../constants';
import { HttpClientService } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeService {

  constructor(
    private http: HttpClientService
  ) { }

  verifyZipCode(data: any) {
    return this.http.post(`${API_CONSTANTS.STATE_OPTION}${API_CONSTANTS.VERIFY_ZIP}`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getStateOption(data: any) {
    return this.http.get(`${API_CONSTANTS.STATE_OPTION}`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getVideoSurvey() {
    return this.http.get(`${API_CONSTANTS.STATE_OPTION}${API_CONSTANTS.VIDEO_SURVEY}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
